<template>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            المقترحات و الشكاوي
          </h2>
          <div id="dataTable_wrapper" class="dataTables_wrapper">
            <form @submit="checkForm">
              <div class="form-group row pB-30 pT-30">
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="supervisorId"
                    :placeholder="this.$t('complaintId')"
                  />
                </div>
                <div class="col-sm-3">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    v-model="supervisorName"
                    :placeholder="this.$t('complaintName')"
                  />
                </div>
                <div class="col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-primary mL-10"
                    :disabled="loading"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
            </form>
            <table
              id="dataTable"
              class="table table-bordered dataTable table-hover"
              role="grid"
              aria-describedby="dataTable_info"
              style="width: 100%"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr role="row">
                  <th
                    class="sorting_asc"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-sort="ascending"
                    aria-label="Name: activate to sort column descending"
                  >
                    #
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    اسم الطالب
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Office: activate to sort column ascending"
                  >
                    المحتوى
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Age: activate to sort column ascending"
                  >
                    النوع
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Start date: activate to sort column ascending"
                  >
                   التاريخ
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loading" v-for="item in supervisors" :key="item.id">
                <tr role="row" class="odd">
                  <td class="sorting_1">{{ item.id }}</td>
                  <td>
                    {{item.student.name }}
                  </td>
                  <td style="direction: ltr;">
                    {{ item.complaint}}
                  </td>
                  <td :style="'color:' + (item.complaint_type.id == 1 ? 'green' :'red')">
                    {{item.complaint_type.ar_name}}
                  </td>
                  <td>{{ formatDate(item.date) }}</td>
                </tr>
              </tbody>
            </table>
            <loading v-show="loading" />
            <p v-show="!supervisors.length" style="text-align: center">
              {{ $t("noData") }}
            </p>
            <div style="text-align:center;margin-top:40px">
            <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 2 " :per-page="10" @paginate="getSupervisors"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "../../components/Loading.vue";
  export default {
    data() {
      return {
        loading: true,
        statusLoading: true,
        supervisorName: "",
        supervisorId: "",
        supervisors: [],
        pagination: {},
        page:1,
      };
    },
    created() {
      this.getSupervisors();
    },
    methods: {
      convertToArabicNumbers(inputString) {
        const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
        // Replace each digit in the string with its Arabic numeral counterpart
        return inputString.replace(/\d/g, digit => arabicNumerals[digit]);
      },
      formatDate(inputDateString) {
    const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];
    const arMonths = [
    "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو",
    "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
];

    const date = new Date(inputDateString);
    const day = date.getDate();
    const month = true? arMonths[date.getMonth()] : months[date.getMonth()];
    const year = date.getFullYear();


    return this.convertToArabicNumbers(`${day} ${month} ,${year}`);

},
      checkForm: function (e) {
        e.preventDefault();
        this.getSupervisors();
      },
      getSupervisors: function () {
        this.loading = true;
        this.$http
          .get("https://yalla-emtihan.com/backend/supervisors/getComplaints", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              supervisor_id: this.supervisorId,
              supervisor_name: this.supervisorName,
              page: this.page
            },
          })
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.supervisors = data.body.data;
                  this.pagination = data.body;
                  console.log(this.pagination);
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            }
          );
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function (message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function (message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>